import {
  Box,
  Typography,
  Stack,
  FormHelperText,
  MenuItem,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactPlayer from 'react-player/youtube';
import useLocales from '../../../../../../lib/hooks/useLocales';
// eslint-disable-next-line import/no-cycle
import { powerOfTrustQuestions } from '../stepsData';
import {
  StyledFormControlLabel,
  StyledRadio,
  StyledRadioGroup,
} from '../../../../PensionProvision/styles';
import { PowerOfAttorneyFormValues } from '#/types/powerOfAttorney';
import Representative from './Representative';
import { RHFSelect } from '#/components/shared/hook-form';
import useAuth from '#/hooks/useAuth';

const childResponsible = [
  {
    value: 'husband_wife',
  },
  {
    value: 'partner',
  },
  {
    value: 'close-friend',
  },
  {
    value: 'brother-sister',
  },
  {
    value: 'brother-sister-in-law',
  },
];

const About = () => {
  const { translate } = useLocales();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { user } = useAuth();

  const civilStatus = user?.civil_status;

  const isMarried =
    civilStatus === 'married' || civilStatus === 'registered_partnership';

  const isSingle =
    civilStatus === 'single' ||
    civilStatus === 'divorced' ||
    civilStatus === 'widowed';

  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const errorKeys = Object.keys(errors);

      const errorElements = errorKeys
        .map((key) => refs.current[key])
        .filter((ref) => ref !== undefined && ref !== null);

      if (errorElements.length > 0) {
        const highestErrorElement = errorElements.reduce((highest, current) => {
          const currentTop = current ? current.getBoundingClientRect().top : 0;
          const highestTop = highest ? highest.getBoundingClientRect().top : 0;

          return currentTop < highestTop ? current : highest;
        }, errorElements[0]);

        if (highestErrorElement) {
          highestErrorElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }
    }
  }, [errors]);

  const handleOnChange = (
    questionName: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue?.(
      questionName as keyof PowerOfAttorneyFormValues,
      event.target.value
    );
  };

  const handleDefaultValues = (question: string) =>
    watch?.(`${question}` as keyof PowerOfAttorneyFormValues);

  const translatedQuestions = powerOfTrustQuestions.map((question, i) => ({
    ...question,
    question: translate(`powerOfAttorney.stepOne.questions.${i}.question`),
    answers: question?.answers?.map((answer, j) => ({
      ...answer,
      label: translate(
        `powerOfAttorney.stepOne.questions.${i}.options.${j}.label`
      ),
    })),
  }));

  const hasChildren = watch('has_children') === 'yes';

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {/* <Typography variant="body1" sx={{ my: 2 }}> */}
      {/*  {String(translate('powerOfAttorney.stepOne.introParagraph'))} */}
      {/* </Typography > */}
      {/* <Typography variant="body1" sx={{ my: 2 }}> */}
      {/*  {String(translate('powerOfAttorney.stepOne.helperParagraph'))} */}
      {/* </Typography> */}
      {/* <Typography variant="body1" sx={{ my: 2 }}> */}
      {/*  {String(translate('powerOfAttorney.stepOne.helperParagraph2'))} */}
      {/* </Typography > */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ReactPlayer
          url="https://youtu.be/BrK2IWCS6a4"
          playing
          light="/assets/images/main-landings/sections/youtubeThumbnail.webp"
          width={670}
        />
      </Box>
      <Stack spacing={5}>
        {translatedQuestions
          .filter((question) => hasChildren || !question.hasChildren)
          .map((question, index) => {
            if (question.id === '6') {
              const nextQuestion = translatedQuestions[index + 1];
              return (
                <Box
                  key={question.id}
                  sx={{
                    width: '100%',
                    display: {
                      xs: 'block',
                      md: 'flex',
                    },
                    justifyContent: 'space-between',
                    gap: '1rem',
                  }}
                >
                  <div
                    style={{ width: '100%' }}
                    ref={(ref) => {
                      refs.current[`${question.value}`] = ref;
                    }}
                  >
                    <Controller
                      name={question.value}
                      control={control}
                      defaultValue={handleDefaultValues(question.value)}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <StyledRadioGroup
                          aria-label={question.id}
                          name={question.value}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleOnChange(question.value, e);
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ mb: 1, mt: index === 0 ? 2 : 0 }}
                          >
                            {String(question.question)}
                          </Typography>
                          <Stack
                            direction={{
                              xs: 'column',
                              md: 'row',
                            }}
                            flexDirection="row"
                            spacing={2}
                          >
                            {!question.answers && !question.select && (
                              <Representative
                                value={question.value}
                                error={error && error?.message}
                                compare={question.compare}
                              />
                            )}
                            {!question.answers && question.select && (
                              <Stack sx={{ width: 1 }}>
                                <RHFSelect
                                  name="trusted_person_role_for_children"
                                  label={String(
                                    translate(
                                      'global.formLabels.trusted_person'
                                    )
                                  )}
                                >
                                  {childResponsible.map((chr) => (
                                    <MenuItem key={chr.value} value={chr.value}>
                                      {String(
                                        translate(
                                          `global.formLabels.trustees_list.${chr.value}`
                                        )
                                      )}
                                    </MenuItem>
                                  ))}
                                </RHFSelect>
                              </Stack>
                            )}
                            {question?.answers &&
                              question?.answers?.map((option) => (
                                <StyledFormControlLabel
                                  key={`step-${question.id}-${option.value}`}
                                  value={option.value}
                                  control={<StyledRadio />}
                                  label={String(option.label)}
                                />
                              ))}
                          </Stack>
                          {!!error && question.answers && (
                            <FormHelperText error={!!error} sx={{ mx: 0 }}>
                              {error && String(translate(error?.message))}
                            </FormHelperText>
                          )}
                        </StyledRadioGroup>
                      )}
                    />
                  </div>
                  {nextQuestion && nextQuestion.id === '7' && (
                    <div
                      style={{ width: '100%' }}
                      ref={(ref) => {
                        refs.current[`${nextQuestion.value}`] = ref;
                      }}
                    >
                      <Controller
                        name={nextQuestion.value}
                        control={control}
                        defaultValue={handleDefaultValues(nextQuestion.value)}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <StyledRadioGroup
                            aria-label={nextQuestion.id}
                            name={nextQuestion.value}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              handleOnChange(nextQuestion.value, e);
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ mb: 1, mt: index === 0 ? 2 : 0 }}
                            >
                              {String(nextQuestion.question)}
                            </Typography>
                            <Stack
                              direction={{
                                xs: 'column',
                                md: 'row',
                              }}
                              flexDirection="row"
                              spacing={2}
                            >
                              {!nextQuestion.answers &&
                                !nextQuestion.select && (
                                  <Representative
                                    value={nextQuestion.value}
                                    error={error && error?.message}
                                    compare={nextQuestion.compare}
                                  />
                                )}
                              {!nextQuestion.answers && nextQuestion.select && (
                                <Stack sx={{ width: 1 }}>
                                  <RHFSelect
                                    name="trusted_person_role_for_children"
                                    label={String(
                                      translate(
                                        'global.formLabels.trusted_person'
                                      )
                                    )}
                                  >
                                    {childResponsible
                                      .filter(
                                        (chr) =>
                                          (isMarried &&
                                            chr.value !== 'partner') ||
                                          (isSingle &&
                                            chr.value !== 'husband_wife')
                                      )
                                      .map((chr) => (
                                        <MenuItem
                                          key={chr.value}
                                          value={chr.value}
                                        >
                                          {String(
                                            translate(
                                              `global.formLabels.trustees_list.${chr.value}`
                                            )
                                          )}
                                        </MenuItem>
                                      ))}
                                  </RHFSelect>
                                </Stack>
                              )}
                              {nextQuestion?.answers &&
                                nextQuestion?.answers?.map((option) => (
                                  <StyledFormControlLabel
                                    key={`step-${nextQuestion.id}-${nextQuestion.value}`}
                                    value={nextQuestion.value}
                                    control={<StyledRadio />}
                                    label={String(option.label)}
                                  />
                                ))}
                            </Stack>
                            {!!error && nextQuestion.answers && (
                              <FormHelperText error={!!error} sx={{ mx: 0 }}>
                                {error && error?.message}
                              </FormHelperText>
                            )}
                          </StyledRadioGroup>
                        )}
                      />
                    </div>
                  )}
                </Box>
              );
            }
            if (question.id !== '7') {
              return (
                <div
                  key={question.id}
                  ref={(ref) => {
                    refs.current[`${question.value}`] = ref;
                  }}
                >
                  <Controller
                    name={question.value}
                    control={control}
                    defaultValue={handleDefaultValues(question.value)}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <StyledRadioGroup
                        aria-label={question.id}
                        name={question.value}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleOnChange(question.value, e);
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ mb: 1, mt: index === 0 ? 2 : 0 }}
                        >
                          {String(question.question)}
                        </Typography>
                        <Stack
                          direction={{
                            xs: 'column',
                            md: 'row',
                          }}
                          flexDirection="row"
                          spacing={2}
                        >
                          {!question.answers && !question.select && (
                            <Representative
                              value={question.value}
                              error={error && error?.message}
                              compare={question.compare}
                            />
                          )}
                          {!question.answers && question.select && (
                            <Stack sx={{ width: 1 }}>
                              <RHFSelect
                                name="trusted_person_role_for_children"
                                label={String(
                                  translate('global.formLabels.trusted_person')
                                )}
                              >
                                {childResponsible.map((chr) => (
                                  <MenuItem key={chr.value} value={chr.value}>
                                    {String(
                                      translate(
                                        `global.formLabels.trustees_list.${chr.value}`
                                      )
                                    )}
                                  </MenuItem>
                                ))}
                              </RHFSelect>
                            </Stack>
                          )}
                          {question?.answers &&
                            question?.answers?.map((option) => (
                              <StyledFormControlLabel
                                key={`step-${question.id}-${option.value}`}
                                value={option.value}
                                control={<StyledRadio />}
                                label={String(option.label)}
                              />
                            ))}
                        </Stack>
                        {!!error && question.answers && (
                          <FormHelperText error={!!error} sx={{ mx: 0 }}>
                            {error && error?.message}
                          </FormHelperText>
                        )}
                      </StyledRadioGroup>
                    )}
                  />
                </div>
              );
            }
            return null;
          })}
      </Stack>
    </Box>
  );
};

export default About;
